import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import PageHeading from "@components/page-heading"
import HeadingBg from "@images/slider-img2.jpg"

export default function NoLogin() {
  return (
    <Layout>
      <SEO />
      <PageHeading
        heading="We apologize for the inconvenience, but our login page is currently unavailable."
        subheading="Our team is working diligently to resolve the issue and restore access as soon as possible.

        We appreciate your patience and understanding.
         
        "
        BgImg={HeadingBg}
        BtnLink="/"
        BtnLabel="BACK TO HOME"
        className="pseudo-none"
      />
    </Layout>
  )
}
